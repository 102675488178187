<template>
    <div class="rule">
        <Top name="支付成功"></Top>
        <div class="success_w">
            <p>订单支付成功，您可使用当前账号登录游戏，在对应区服（角色）收取商品。<br />(商品将在5分钟内转移到购买账号下,超过5分钟未收到商品请联系客服）</p>
            <div class="login_btns">
                <van-button round type="info" size="large" color="linear-gradient(to right, #FF704D, #F73727)" @click="$router.push('/')">返回首页</van-button>
            </div>
        </div>
    </div>
</template>
<script>
 import Top from '@/components/top.vue'

export default {
    name: 'Paysuccess',
    components: {
        Top,
    },
    data() {
        return {

        }
    },
}
</script>
<style scoped>
.rule{
    min-height: 100%;
    background-color: #fff;
    position: relative;
}
.success_w{
    padding: 5%;
    font-size: .8125rem;
    text-align: left;
}
.success_w .login_btns{
    width: 100%;
    position: absolute;
    left: 50%;
    bottom: 5%;
    transform: translate(-50%, 0);
}


</style>